<template>
	<div class="register">
		<div style="width: 600px;margin: auto;">
			<div class="register-title">{{ $t("register.sentInvoice") }}</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm"
				hide-required-asterisk="" style="width: 600px">
				<el-form-item :label="$t('register.companyNameLabel')" prop="companyNameLabel">
					<el-input v-model="ruleForm.companyNameLabel"></el-input>
				</el-form-item>
				<el-form-item :label="$t('register.Enterprise_Tax')">
					<el-input v-model="ruleForm.Enterprise_Tax"></el-input>
				</el-form-item>
				<el-form-item :label="$t('register.acceptEmaill')" prop="rejectemail">
					<el-input v-model="ruleForm.rejectemail"></el-input>
				</el-form-item>
				<el-form-item :label="$t('register.depositBank')" prop="depositBank">
					<el-input v-model="ruleForm.depositBank"></el-input>
				</el-form-item>
				<el-form-item :label="$t('register.bankAccount')" prop="bankAccount">
					<el-input v-model="ruleForm.bankAccount"></el-input>
				</el-form-item>
				<!-- <el-form-item :label="$t('register.addressLabel')" prop="addressLabel">
				<el-input v-model="ruleForm.addressLabel"></el-input>
			</el-form-item> -->
				<el-form-item>
					<el-button type="primary" @click="next('ruleForm')" style="width: 100%">{{
            Authorization ? $t("register.editTxt") : $t("register.next")
          }}
					</el-button>
				</el-form-item>

				<el-form-item v-if="fromPath!=='/goodsPay'">
					<router-link to>
						<el-button type="primary" plain @click="$router.back(-1)" style="width: 100%">
							{{ $t("register.prev") }}
						</el-button>
					</router-link>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>
<script>
	import {
		mapMutations,
		mapState
	} from "vuex";
	import {
		register,
		getinvoiceInfo,
		editInvoice,
		Changeregister
	} from "@/api/User";
	import {
		getToken,
		setToken
	} from "@/utils/auth";

	export default {
		computed: {
			...mapState(["fromPath"]),
		},
		data() {
			var Emaillvalidator = (rule, value, callback) => {
				const regEmaill =
					/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
				if (regEmaill.test(value)) {
					this.$req.post("/client/im/user/checkEmail", {
						email: value
					}).then(res => {
						if (res) {
							console.log(this.ruleForm.rejectemail);
							console.log(value + "::::");
							if (this.ruleForm.id > 0 && this.ruleForm.rejectemail == value) {
								callback();	
							}else {
								callback(new Error(this.$t("newadd.t.该邮箱已注册")));
							}
						} else {
							callback();
						}
					})
				} else {
					callback(new Error(this.$t("register.EmaillTag")));
				}
			};
			return {
				ruleForm: {
					companyNameLabel: "",
					Enterprise_Tax: "",
					rejectemail: "",
					depositBank: "",
					bankAccount: "",
					// addressLabel: "",
					id: "",
					userId: "",
				},
				rules: {
					companyNameLabel: [{
							required: true,
							message: this.$t("register.mesCompanyEmpty"),
							trigger: "blur",
						},
						{
							min: 3,
							max: 50,
							message: this.$t("register.mesStrLength"),
							trigger: "blur",
						},
					],
					rejectemail: [{
							required: true,
							message: this.$t("register.EmaillTag"),
							trigger: "blur",
						},
						{
							validator: Emaillvalidator,
							trigger: "blur",
						},
					]
				},
				Authorization: getToken()
			};
		},
		created() {

			if (this.Authorization) {
				getinvoiceInfo()
					.then((res) => {
						const {
							companyName,
							dutyParagraph,
							address,
							bank,
							bankAccount,
							email,
							id,
							userId
						} = res;
						this.ruleForm.companyNameLabel = companyName;
						this.ruleForm.Enterprise_Tax = dutyParagraph;
						this.ruleForm.depositBank = bank;
						this.ruleForm.rejectemail = email;
						this.ruleForm.bankAccount = bankAccount;
						// this.ruleForm.addressLabel = address;
						this.ruleForm.id = id;
						this.ruleForm.userId = userId;


					})
					.catch((err) => {});
			}
		},

		methods: {
			next(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.toSubmint();
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			toSubmint() {
				var obj2 = this.ruleForm;
				var obj1 = this.$route.query;
				let newObj = {};
				Object.assign(newObj, obj2, obj1);
				// 是从订单来的 需要开发票
				if (this.fromPath == "/goodsPay" || this.fromPath == "/goodsNormallPay" || this.fromPath ==
					"/orderadmin") {

					editInvoice(obj2)
						.then((res) => {
							this.$message({
								message: "修改",
								type: "success",
								onClose: (res) => {
									this.$router.go(-1)
								},
							});
						})
						.catch((err) => {});

				} else {
					// 邮箱没有注册
					if (this.$route.query.email) {
						register(newObj)
							.then((res) => {
								setToken(res);
								this.$message({
									message: this.$t("newadd.t.登陆成功"),
									type: "success",
									onClose: (res) => {
										this.$router.push({
											path: "/"
										}, () => {
											location.reload()
										});
									},
								});
							})
							.catch((err) => {});
					} else {
						Changeregister(newObj)
							.then((res) => {

								this.$message({
									message: "修改成功",
									type: "success",
									onClose: (res) => {
										this.$router.push({
											path: "/"
										}, () => {
											location.reload()
										});
									},
								});
							})
							.catch((err) => {

							});
					}


				}


			},
		},
	};
</script>
<style scoped>
	.register {
		background: #fff;
		padding: 40px 0;
		height: 100vh;
	}

	.register-title {
		text-align: center;
		font-size: 24px;
		margin: 30px auto;
		width: 550px;
	}

	.input-line {
		display: flex;
		justify-content: space-between;
	}
</style>
